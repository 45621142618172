/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  padding-top: 70px;
  margin-bottom: 60px; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  background-color: #f5f5f5;
}


/* Custom page CSS
 * Not required for template or sticky footer method.
 * -------------------------------------------------- */

.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}
.container .text-muted {
  margin: 20px 0;
}

.Accordion {
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.Accordion__header {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
  border-bottom: 0;
  padding: 10px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
  .Accordion__header:after {
    content: '';
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    right: 15px;
    transform: rotate(180deg);
    transition: transform 0.3s; }

.Accordion__header--collapsed:after {
  transform: rotate(0deg); }

.Accordion__body {
  height: auto;
  opacity: 1;
  overflow-y: hidden;
  overflow-x: auto;
  transition: all 0.2s;
  padding: 15px 15px; }

.Accordion__body--collapsed {
  height: 0;
  opacity: 0;
  padding: 0 70px;
  transition: all 0.2s; }